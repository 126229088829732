import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import PillButton from "../../../elements/V2/Buttons/PillButton"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Typography from "../../../elements/V2/Typography"

import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"
import { getAccentColor, getBackgroundColorClass } from "@utils/V2/color"

type Props = {
  cardBackgroundColor: Color
  text: string
  buttonText: string
  buttonLink: string
  buttonBorderColor: Color
  buttonStyle?: "solid" | "bordered"
  imageUrl: string
  imageAlt: string
}

const ContentBanner = ({
  cardBackgroundColor,
  text,
  buttonText,
  buttonLink,
  buttonBorderColor,
  buttonStyle = "bordered",
  imageUrl,
  imageAlt,
  ...props
}: Props) => {
  const { animationRefCallback } = useScrollAnimateSelf(true)

  return (
    <div
      ref={animationRefCallback}
      className={classNames(
        "rounded-lg-v2 col-span-4 grid grid-cols-1 overflow-hidden sm-v2:col-span-4 sm-v2:grid-cols-2 md-v2:col-span-6 lg-v2:col-span-12",
        getBackgroundColorClass(cardBackgroundColor)
      )}
      {...props}
    >
      <div className="flex flex-col items-start gap-20-v2 px-20-v2 py-24-v2 sm-v2:py-32-v2 md-v2:gap-32-v2 md-v2:px-32-v2 md-v2:py-48-v2">
        <Typography
          text={text}
          font="grotesk"
          weight="book"
          size="lead-lg"
          color={getAccentColor(cardBackgroundColor)}
        />
        <PillButton
          size="large"
          style={buttonStyle}
          text={buttonText}
          color={buttonBorderColor}
          linkUrl={buttonLink}
          overrideTextColor={getAccentColor(cardBackgroundColor)}
        />
      </div>
      <div className="relative">
        <div className="aspect-square w-full md-v2:absolute md-v2:left-0 md-v2:top-1/2 md-v2:-translate-y-1/2">
          <GatsbyStoryblokImage
            image={imageUrl}
            alt={imageAlt || "Banner image"}
            aspectRatio="1:1"
            className="h-full w-full"
          />
        </div>
      </div>
    </div>
  )
}

export default ContentBanner
